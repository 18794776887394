import { HOME_PAGE } from '@app/constants/routes';
import { getSession } from 'next-auth/react';
import RegisterLogin from '@app/components/register-login';

export const getServerSideProps = async (context) => {
  const session = await getSession(context);

  // Redirect if already authenticated
  if (session) {
    return {
      redirect: {
        destination: HOME_PAGE,
        permanent: true,
      },
    };
  }

  return {
    props: {},
  };
};

const LoginPage = () => (
  <RegisterLogin tab="login" />
);

export default LoginPage;
